import React from 'react'
import useFetch from '../hooks/useFetch'
import Logo from '../assets/logo.png'
import { Link } from 'react-router-dom'
import { Shimmer } from 'react-shimmer'

export default function News() {
    const { loading, posts } = useFetch()
    const deviceWidth = window.innerWidth;

    return (
        <section className="bg-white w-full dark:bg-gray-900 font-montserrat">
            <div className="py-8 mx-auto lg:py-16 ">
                <div className="mx-auto max-w-screen-sm text-center lg:mb-16 mb-8 absolute top-80 sm:top-72 md:top-72 lg:top-72 xl:top-72 left-0 right-0">
                    <h2 className="mb-4 text-2xl sm:text-3xl lg:text-4xl tracking-tight font-extrabold text-white dark:text-white">Aktuelles</h2>
                    <p className="font-light text-slate-100 text-sm sm:text-lg lg:text-xl text-white dark:text-gray-400">Alle wichtigen Nachrichten und Updates unseres Vereins findest du hier!</p>
                </div>
                <div className='bg-white w-full min-h-50vh flex justify-center pt-4'>
                    {!loading && posts && posts.length > 0 &&
                    <div className="grid gap-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 px-4 w-full xl:w-3/4">
                        {posts.map((post, index) => (
                            <Link to={`/news/${index}`} key={index} className={`${post.metadata.prev_image != null ? 'p-0' : 'p-4'} bg-slate-100 rounded-2xl border flex flex-col h-80 sm:h-96 border-gray-200 shadow-xl shadow-gray-300 hover:shadow-gray-400 hover:cursor-pointer dark:bg-gray-800 dark:border-gray-700`}>
                                <img alt='Bild Artikel' className={`rounded-t-2xl h-2/3 sm:w-full ${post.metadata.prev_image != null ? 'object-cover' : 'object-contain'} sm:h-2/3`} src={post.metadata.prev_image != null ? (post.metadata.prev_image) : Logo} />
                                <div className='p-3 sm:px-6 flex flex-col flex-1'>
                                    <div className='flex-1'>
                                        <p className="mb-2 text-xs sm:text-sm font-medium text-gray-600 dark:text-primary-500">{new Date(post.metadata.date).toLocaleString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit'})}</p>
                                        <h2 className="mb-2 text-sm sm:text-md lg:text-lg font-semibold tracking-tight text-gray-900 dark:text-white"><a href="/">{post.metadata.title.length > 30 ? post.metadata.title.slice(0,30) + ' ...' : post.metadata.title}</a></h2>
                                    </div>
                                    <div className="flex justify-end" >
                                        <Link to={`/news/${index}`} className="inline-flex text-xs sm:text-sm items-center font-medium text-primary-600 dark:text-primary-500 hover:underline">
                                            Read more
                                            <svg className="ml-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                        </Link>
                                    </div>
                                </div> 
                            </Link>
                        ))}
                    </div>}
                    {!loading && (!posts || posts.length === 0) && 
                        <div className="sm:p-6 p-3 mx-3 text-center bg-slate-100 rounded-lg border self-center items-center border-gray-200 shadow-xl dark:bg-gray-800 dark:border-gray-700">
                            <h2 className="mb-2 text-lg sm:text-xl font-bold tracking-tight text-gray-900 dark:text-white">Keine Artikel vorhanden</h2>
                            <p className="mb-5 text-sm sm:text-lg font-light text-gray-500 dark:text-gray-400">Es wurden keine Artikel gefunden. Bitte versuchen Sie es später erneut.</p>
                        </div>
                    }
                    {loading &&
                        <div className='grid gap-8 sm:grid-cols-2 px-4 w-full'>
                            <Shimmer height={200} width={deviceWidth/2 - 40} className='mt-4 rounded-2xl'/>
                            <Shimmer height={200} width={deviceWidth/2 - 40} className='mt-4 rounded-2xl'/>
                            <Shimmer height={200} width={deviceWidth/2 - 40} className='mt-4 rounded-2xl'/>
                            <Shimmer height={200} width={deviceWidth/2 - 40} className='mt-4 rounded-2xl'/>
                        </div>
                    }
                </div>
            </div>
        </section>
    )
}
