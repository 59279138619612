
import React from 'react'
import trophy from '../assets/trophy-solid.png'
import community from '../assets/people-roof-solid.png'
import children from '../assets/children-solid.png'
import health from '../assets/heart-pulse-solid.png'
import ghost from '../assets/ghost-solid.png'
import experience from '../assets/experience.png'


export default function Features() { 
    return (
        <section className="bg-white dark:bg-gray-900 mt-16 mb-16">
            <div className="px-4 mx-auto max-w-screen-xl">
                <div className="min-w-screen-md mb-8 lg:mb-12 text-center">
                    <h2 className="mb-4 text-2xl sm:text-3xl md:text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">TTC Rehlingen - unser Verein</h2>
                    <p className="text-gray-500 text-sm sm:text-lg md:text-xl dark:text-gray-400">Familiäre Atmosphäre und vielfältige Möglichkeiten</p>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 px-4 xl:px-0">
                    <div>
                        <div className="flex justify-center items-center mb-2 rounded-full bg-primary-100 h-8 w-8 sm:h-12 sm:w-12 dark:bg-primary-900">
                            <img src={community} className="w-4 sm:w-6" alt='Icon' />
                        </div>
                        <h3 className="mb-2 text-lg sm:text-xl font-bold dark:text-white">Gemeinschaft</h3>
                        <p className="text-gray-500 dark:text-gray-400 text-xs sm:text-sm">Die Gemeinschaft ist uns sehr wichtig und wir heißen jeden herzlich willkommen.</p>
                    </div>
                    <div>
                        <div className="flex justify-center items-center mb-2 rounded-full bg-primary-100 h-8 w-8 sm:h-12 sm:w-12 dark:bg-primary-900">
                            <img src={ghost} className="w-3 sm:w-5" alt='Icon' />
                        </div>
                        <h3 className="mb-2 text-lg sm:text-xl font-bold dark:text-white">Teamgeist</h3>
                        <p className="text-gray-500 dark:text-gray-400 text-xs sm:text-sm">Durch das gemeinsame Training und Wettkämpfe wird der Teamgeist gestärkt und der Zusammenhalt gefördert.</p>
                    </div>
                    <div>
                        <div className="flex justify-center items-center mb-2 rounded-full bg-primary-100 h-8 w-8 sm:h-12 sm:w-12 dark:bg-primary-900">
                            <img src={children} className="w-4 sm:w-6" alt='Icon' />
                        </div>
                        <h3 className="mb-2 text-lg sm:text-xl font-bold dark:text-white">Nachwuchsförderung</h3>
                        <p className="text-gray-500 dark:text-gray-400 text-xs sm:text-sm">Der Verein legt großen Wert auf die Förderung junger Talente und freut sich über jeden neuen, jungen Zugang.</p>
                    </div>
                    <div>
                        <div className="flex justify-center items-center mb-2 rounded-full bg-primary-100 h-8 w-8 sm:h-12 sm:w-12 dark:bg-primary-900">
                            <img src={experience} className="w-4 sm:w-6" alt='Icon' />
                        </div>
                        <h3 className="mb-2 text-lg sm:text-xl font-bold dark:text-white">Erfahrung</h3>
                        <p className="text-gray-500 dark:text-gray-400 text-xs sm:text-sm">Die erfahrenen Spielerinnen und Spieler stehen den Neulingen mit Rat und Tat zur Seite und vermitteln ihr Wissen und ihre Erfahrung gerne weiter.</p>
                    </div>
                    <div>
                        <div className="flex justify-center items-center mb-2 rounded-full bg-primary-100 h-8 w-8 sm:h-12 sm:w-12 dark:bg-primary-900">
                            <img src={health} className=" w-4 sm:w-6" alt='Icon'/>
                        </div>
                        <h3 className="mb-2 text-lg sm:text-xl font-bold dark:text-white">Gesundheit</h3>
                        <p className="text-gray-500 dark:text-gray-400 text-xs sm:text-sm">Tischtennis ist ein Sport, der Ausdauer, Konzentration und Koordination fördert und somit einen wichtigen Beitrag zur Gesundheit leistet.</p>
                    </div>
                    <div>
                        <div className="flex justify-center items-center mb-2 rounded-full bg-primary-100 h-8 w-8 sm:h-12 sm:w-12 dark:bg-primary-900">
                            <img src={trophy} className="w-4 sm:w-6" alt='Icon' />
                        </div>
                        <h3 className="mb-2 text-lg sm:text-xl font-bold dark:text-white">Wettkampf</h3>
                        <p className="text-gray-500 dark:text-gray-400 text-xs sm:text-sm">Der Verein bietet seinen Mitgliedern die Möglichkeit, an Einzel -und Mannschaftswettkämpfen teilzunehmen.</p>
                    </div>
                </div>
            </div>
        </section>
    )
}
