import React from 'react'
import { useState } from 'react';

export default function NavBarDropdown () {
    const [isOpen, setOpen] = useState(false);
  
    const handleDropDown = () => {
      setOpen(!isOpen);
    };
  
    return (
      <div className="dropdown font-montserrat">
        <button
          className="font-montserrat font-medium focus:outline-none  rounded-lg text-center inline-flex items-center text-gray-700 border-b border-gray-100 hover:text-blue-500 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
          onClick={handleDropDown} >
          Verein
          <svg
            className="ml-2 w-4 h-4"
            aria-hidden="true"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 9l-7 7-7-7"
            ></path>
          </svg>
        </button>
  
        <div
          id="dropdown"
          className={`z-10 bg-white rounded-lg mt-2 divide-y divide-gray-100 shadow absolute ${isOpen ? "block" : "hidden"}`}>
          <ul className=" z-10 bg-white rounded-lg divide-y divide-gray-100 shadow ">
              <li>
                <a href="/vorstand" className="block py-3 px-5 text-gray-700 hover:text-blue-500 lg:hover:bg-transparent">Vorstand</a>
              </li>
              <li>
                <a href="/Mannschaften" className="block py-3 px-5 text-gray-700 hover:text-blue-500 lg:hover:bg-transparent">Mannschaften</a>
              </li>
              <li>
                <a href="/docs" className="block py-3 px-5 text-gray-700 hover:text-blue-500 lg:hover:bg-transparent">Downloads</a>
              </li>
              <li>
                <a href="/chronik" className="block py-3 px-5 text-gray-700 hover:text-blue-500 lg:hover:bg-transparent">Chronik</a>
              </li>
          </ul>
        </div>
      </div>
    );
  };
