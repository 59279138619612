import React from 'react'

function Training() {
    return (
        <section className="bg-white dark:bg-gray-900 mt-8">
            <div className="mb-60px text-center px-2">
                <h2 className="mb-4 text-2xl sm:text-3xl md:text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">Training</h2>
                <p className="text-gray-500 text-sm mb-3 sm:text-lg md:text-xl dark:text-gray-400">Du bist jederzeit bei uns willkommen, schau einfach vorbei.</p>
            </div>
            <div className="max-w-screen-xl px-4 mx-auto text-center">
                <dl className="grid max-w-screen-md gap-4 mx-auto text-gray-900 grid-cols-2 sm:grid-cols-3 dark:text-white">
                    <div className="flex flex-col items-center justify-center gap-3">
                        <dt className="sm:mb-2 text-xl sm:text-2xl md:text-3xl font-bold">Dienstag</dt>
                        <dd className="font-semibold text-gray-500 dark:text-gray-400 text-xs sm:text-lg">17:30 - 19:00</dd>
                        <dd className="font-semibold text-gray-500 dark:text-gray-400 text-xs sm:text-lg">19:00 - 22:00</dd>
                    </div>
                    <div className="flex flex-col items-center justify-end gap-3 text-xs sm:text-lg z-0">
                        <dd className="font-semibold text-gray-500 opacity-80 dark:text-gray-400">Schüler</dd>
                        <dd className="font-semibold text-gray-500 opacity-80 dark:text-gray-400">Erwachsene</dd>
                    </div>
                    <div className="flex flex-col items-center justify-end gap-3 text-xs sm:text-lg sm:hidden z-0">
                        <dd className="font-semibold text-gray-500 opacity-80 dark:text-gray-400">Schüler</dd>
                        <dd className="font-semibold text-gray-500 opacity-80 dark:text-gray-400">Erwachsene</dd>
                    </div>
                    <div className="flex flex-col items-center justify-center gap-3 mt-3 sm:mt-0">
                        <dt className="sm:mb-2 text-xl sm:text-2xl md:text-3xl font-bold">Donnerstag</dt>
                        <dd className="font-semibold text-gray-500 dark:text-gray-400 text-xs sm:text-lg">17:30 - 19:00</dd>
                        <dd className="font-semibold text-gray-500 dark:text-gray-400 text-xs sm:text-lg">19:00 - 22:00</dd>
                    </div>
                    
                </dl>
            </div>
        </section>
    )
}

export default Training