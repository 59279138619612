import React from 'react'

const DOWNLOAD_BASE_URL = 'https://ttcrehlingen.de/docs/'

function DorfSpieltTT() {
    return (
        <section className="bg-white dark:bg-gray-900 mt-8 mb-8">
            <div className="mb-60px text-center px-2">
                <h2 className="mb-4 text-2xl sm:text-3xl md:text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">Dorf spielt Tischtennis 2024</h2>
                <p className="text-gray-500 text-sm sm:text-lg md:text-xl dark:text-gray-400">Auch in diesem Jahr findet wieder unser alljährliches Dorfturnier statt.</p>
                <p className="text-gray-500 text-sm mb-5 mt-3 sm:text-lg md:text-xl dark:text-gray-400">Das Anmeldeformular steht über den Button unten zum Download bereit <br/>und kann per Mail an ttcrehlingen@ttcrehlingen.de gesendet werden.</p>
            </div>
            <div className="max-w-screen-xl px-4 mx-auto text-center">
                <dl className="grid max-w-screen-md gap-4 mx-auto text-gray-900 grid-cols-2 sm:grid-cols-3 dark:text-white">
                    <div className="flex flex-col items-center justify-center gap-3">
                        <dt className="sm:mb-2 text-lg sm:text-xl md:text-2xl font-semibold">Wann?</dt>
                        <dd className="font-semibold text-gray-500 dark:text-gray-400 text-xs sm:text-lg">28.12.24, 10:00 Uhr</dd>
                    </div>
                    <div className="flex flex-col items-center justify-end gap-3 text-xs sm:text-lg z-0">
                        <dt className="sm:mb-2 text-lg sm:text-xl md:text-2xl font-semibold">Wo?</dt>
                        <dd className="font-semibold text-gray-500 dark:text-gray-400 text-xs sm:text-lg">Schulturnhalle</dd>
                    </div>
                    <div className="flex flex-col items-center justify-center gap-3 mt-3 sm:mt-0">
                        <dt className="sm:mb-2 text-lg sm:text-xl md:text-2xl font-semibold">Wer?</dt>
                        <dd className="font-semibold text-gray-500 dark:text-gray-400 text-xs sm:text-lg">3er Teams</dd>
                    </div>
                    
                </dl>
            </div>

            <div className="flex justify-center mt-8">
                <a href={DOWNLOAD_BASE_URL + 'DsTTAnmeldung.pdf'} download className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Anmeldeformular herunterladen</a>
            </div>
        </section>
    )
}

export default DorfSpieltTT