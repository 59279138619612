
import React, { useEffect, useState } from 'react';
import LeftArrow from '../assets/left.png';
import RightArrow from '../assets/right.png';

export default function ImageCarousel({images, activeSlide, openModal, closeModal}) {
  const [slide, setSlide] = useState(activeSlide);

  useEffect(() => {
    setSlide(activeSlide);
  }, [activeSlide]);

  const nextSlide = () => {
    setSlide(slide === images.length - 1 ? 0 : slide + 1);
  };

  const prevSlide = () => {
    setSlide(slide === 0 ? images.length - 1 : slide - 1);
  };

  const handleCloseModal = () => {
    closeModal();
  }



  return (
    <div className={`w-full h-full bg-opacity-60 bg-black fixed top-0 left-0 right-0 bottom-0 ${openModal ? 'block' : 'hidden'}`}>
      <button onClick={handleCloseModal} className='fixed z-10 top-4 right-4 w-12 h-12 rounded-full bg-white bg-opacity-70 text-gray-900 text-4xl'>&times;</button>
      <div onClick={prevSlide} className="z-30 absolute w-12 h-12 bg-white bg-opacity-70 rounded-full hover:cursor-pointer left-4 bottom-1/2 top-1/2 transform -translate-y-1/2">
        <img src={LeftArrow} alt='left arrow' className='w-8 mx-auto self-center mt-2'/>
      </div>
      {images && images.length > 0 && images.map((item, idx) => {
        return (
          <img
            src={item.src}
            alt={item.alt}
            key={idx}
            className={slide === idx ? "rounded-3xl top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-3/4 absolute object-contain" : "rounded-sm w-full h-full hidden"}
          />
        );
      })}
      <div onClick={nextSlide} className="absolute w-12 h-12 bg-white bg-opacity-70 rounded-full hover:cursor-pointer right-4 bottom-1/2 top-1/2 transform -translate-y-1/2">
        <img src={RightArrow} alt='left arrow' className='w-8 mx-auto self-center mt-2'/>
      </div>
      <span className="flex absolute bottom-4 left-1/2 transform -translate-x-1/2">
        {images && images.map((_, idx) => {
          return (
            <button
              key={idx}
              className={
                slide === idx ? "bg-white h-3 w-3 rounded-full cursor-pointer mx-1" : "bg-gray-500 h-3 w-3 rounded-full cursor-pointer mx-1"
              }
              onClick={() => setSlide(idx)}
            ></button>
          );
        })}
      </span>
    </div>
  )
}
