import React from 'react'
import ArticleOverview from '../components/ArticleOverview'
import Features from '../components/Features'
import Training from '../components/Training'
import DorfSpieltTT from '../components/DorfSpieltTT'


export default function Home() {
    
  return (
    <section className="bg-gray-600 mt-0 dark:bg-gray-900 font-montserrat">
      <div className="gap-20 items-center py-4 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-4 lg:px-6 absolute top-40 sm:top-48 md:top-28 lg:top-22 left-0 right-0">
          <div className="font-medium text-sm sm:text-sm md:text-lg self-end lg:mb-8 dark:text-gray-400 lg:w-auto md:w-2/3 mx-auto sm:w-3/4 w-11/12 ">
              <h2 className="mb-4 text-xl sm:text-2xl lg:text-4xl tracking-tight font-extrabold text-white dark:text-white">Tischtennis verbindet.</h2>
              <p className="mb-4 text-white ">In unserem kleinen Tischtennisverein in Rehlingen steht die Gemeinschaft im Vordergrund. Denn es geht nicht nur um den sportlichen Erfolg, sondern vor allem um den Zusammenhalt und den Spaß.</p>
              <p className='text-white'>Bei uns ist jeder willkommen - vom Anfänger bis zum Profi. Wir freuen uns auf dich!</p>
          </div>
          <div className="hidden lg:grid grid-cols-2 gap-4 self-end">
              <img className="w-full rounded-lg" src={require('../assets/home1.jpg')} alt="office content 1"/>
              <img className="mt-4 w-full lg:mt-10 rounded-lg" src={require('../assets/home2.jpg')} alt="office content 2"/>
          </div>
      </div>
      <div className='bg-white flex flex-col'>
        <DorfSpieltTT />
        <Training />
        <ArticleOverview />
        <Features />
      </div>
    </section>
  )
}
