import React from "react";
import left from "../assets/left.png";
import right from "../assets/right.png";
import up from "../assets/up.png";
import parking from "../assets/parking.png";
import car from "../assets/car.png";
import person from "../assets/person.png";

export default function Anfahrt() {
  return (
    <section className="bg-white dark:bg-gray-900">
      <div className="py-8 px-4 mx-auto lg:px-0">
        <div className="mx-auto max-w-screen-sm text-center lg:mb-16 mb-8 absolute top-80 sm:top-72 md:top-72 lg:top-72 xl:top-72 left-0 right-0">
          <h2 className="mb-4 text-2xl sm:text-3xl lg:text-4xl tracking-tight font-extrabold text-white dark:text-white">
            Anfahrt
          </h2>
          <p className="font-light text-slate-100 text-sm sm:text-lg lg:text-xl text-white dark:text-gray-400">
            So erreicht ihr uns.
          </p>
        </div>
        <div className="bg-white w-full pt-4">
          <section className="w-full dark:bg-gray-900 antialiased flex flex-col lg:flex-row">
            <div className="max-w-screen-lg flex-1 px-4  mx-auto flex flex-col">
              <h2 className="self-center  text-lg font-medium text-primary-600 dark:text-primary-500">
                von Beckingen
              </h2>
              <div className="flow-root w-full mx-auto mt-6 sm:mt-6 lg:mt-10">
                <h3 className="text-md text-center font-bold text-gray-900 dark:text-white mb-6">
                  Halleneingang vorne
                </h3>
                <div className="-my-4 divide-y divide-gray-200 dark:divide-gray-700">
                  <div className="flex flex-row gap-2 py-4 sm:gap-6 items-center">
                    <div className="w-1/5 flex flex-row justify-around">
                      <img className="h-4 sm:h-6" src={car} alt="" />
                      <img className="h-4 sm:h-6" src={up} alt="" />
                    </div>
                    <h3 className="text-sm font-semibold text-gray-900 dark:text-white">
                      Einfahrt Rehlingen über die Brücke
                    </h3>
                  </div>

                  <div className="flex flex-row gap-2 py-4 sm:gap-6 items-center">
                    <div className="w-1/5 flex flex-row justify-around">
                      <img className="h-4 sm:h-6" src={car} alt="" />
                      <img className="h-4 sm:h-6" src={up} alt="" />
                    </div>
                    <h3 className="text-sm font-semibold text-gray-900 dark:text-white">
                      Geradeaus auf der Beckinger Straße
                    </h3>
                  </div>

                  <div className="flex flex-row gap-2 py-4 sm:gap-6 items-center">
                    <div className="w-1/5 flex flex-row justify-around">
                      <img className="h-4 sm:h-6" src={car} alt="" />
                      <img className="w-4 sm:w-6" src={parking} alt="" />
                    </div>
                    <h3 className="text-sm font-semibold text-gray-900 dark:text-white">
                      Parken am Sportplatz
                    </h3>
                  </div>

                  <div className="flex flex-row gap-2 py-4 sm:gap-6 items-center">
                    <div className="w-1/5 flex flex-row justify-around">
                      <img className="h-4 sm:h-6" src={person} alt="" />
                      <img className="w-4 sm:w-6" src={left} alt="" />
                    </div>
                    <h3 className="text-sm font-semibold text-gray-900 dark:text-white">
                      Zu Fuß vor Realschule links abbiegen
                    </h3>
                  </div>

                  <div className="flex flex-row gap-2 py-4 sm:gap-6 items-center">
                    <div className="w-1/5 flex flex-row justify-around">
                      <img className="h-4 sm:h-6" src={person} alt="" />
                      <img className="h-4 sm:h-6" src={up} alt="" />
                    </div>
                    <h3 className="text-sm font-semibold text-gray-900 dark:text-white">
                      Geradeaus Eingang zur Halle
                    </h3>
                  </div>
                </div>
              </div>

              <div className="flow-root w-full mx-auto mt-14 lg:mt-14">
                <h3 className="text-md text-center font-bold text-gray-900 dark:text-white mb-6">
                  Halleneingang hinten
                </h3>
                <div className="-my-4 divide-y divide-gray-200 dark:divide-gray-700">
                  <div className="flex flex-row gap-2 py-4 sm:gap-6 items-center">
                    <div className="w-1/5 flex flex-row justify-around">
                      <img className="h-4 sm:h-6" src={car} alt="" />
                      <img className="h-4 sm:h-6" src={up} alt="" />
                    </div>
                    <h3 className="text-sm font-semibold text-gray-900 dark:text-white">
                      Geradeaus auf der Beckinger Straße
                    </h3>
                  </div>

                  <div className="flex flex-row gap-2 py-4 sm:gap-6 items-center">
                    <div className="w-1/5 flex flex-row justify-around">
                      <img className="h-4 sm:h-6" src={car} alt="" />
                      <img className="h-4 sm:h-6" src={left} alt="" />
                    </div>
                    <h3 className="text-sm font-semibold text-gray-900 dark:text-white">
                      Links abbiegen auf Feldstraße
                    </h3>
                  </div>

                  <div className="flex flex-row gap-2 py-4 sm:gap-6 items-center">
                    <div className="w-1/5 flex flex-row justify-around">
                      <img className="h-4 sm:h-6" src={car} alt="" />
                      <img className="h-4 sm:h-6" src={right} alt="" />
                    </div>
                    <h3 className="text-sm font-semibold text-gray-900 dark:text-white">
                      Rechts abbiegen auf Neustraße
                    </h3>
                  </div>

                  <div className="flex flex-row gap-2 py-4 sm:gap-6 items-center">
                    <div className="w-1/5 flex flex-row justify-around">
                      <img className="h-4 sm:h-6" src={car} alt="" />
                      <img className="w-4 sm:w-6" src={parking} alt="" />
                    </div>
                    <h3 className="text-sm font-semibold text-gray-900 dark:text-white">
                      Parken am Marktplatz
                    </h3>
                  </div>

                  <div className="flex flex-row gap-2 py-4 sm:gap-6 items-center">
                    <div className="w-1/5 flex flex-row justify-around">
                      <img className="h-4 sm:h-6" src={person} alt="" />
                      <img className="h-4 sm:h-6" src={up} alt="" />
                    </div>
                    <h3 className="text-sm font-semibold text-gray-900 dark:text-white">
                      Durch Tor Richtung Schulhof
                    </h3>
                  </div>

                  <div className="flex flex-row gap-2 py-4 sm:gap-6 items-center">
                    <div className="w-1/5 flex flex-row justify-around">
                      <img className="h-4 sm:h-6" src={person} alt="" />
                      <img className="h-4 sm:h-6" src={right} alt="" />
                    </div>
                    <h3 className="text-sm font-semibold text-gray-900 dark:text-white">
                      Rechts Richtung hinterer Eingang
                    </h3>
                  </div>
                </div>
              </div>
            </div>

            <div className="max-w-screen-xl flex-1 px-4  mx-auto flex flex-col mt-16 lg:mt-0">
              <h2 className="self-center  text-lg font-medium text-primary-600 dark:text-primary-500">
                von Fremersdorf
              </h2>
              <div className="flow-root w-full mx-auto mt-6 sm:mt-6 lg:mt-10">
                <h3 className="text-md text-center font-bold text-gray-900 dark:text-white mb-6">
                  Halleneingang vorne
                </h3>
                <div className="-my-4 divide-y  divide-gray-200 dark:divide-gray-700">
                  <div className="flex flex-row gap-2 py-4 sm:gap-6 items-center">
                    <div className="w-1/5 flex flex-row justify-around">
                      <img className="h-4 sm:h-6" src={car} alt="" />
                      <img className="h-4 sm:h-6" src={up} alt="" />
                    </div>
                    <h3 className="text-sm font-semibold text-gray-900 dark:text-white">
                      Geradeaus auf Fremersdorfer Straße
                    </h3>
                  </div>

                  <div className="flex flex-row gap-2 py-4 sm:gap-6 items-center">
                    <div className="w-1/5 flex flex-row justify-around">
                      <img className="h-4 sm:h-6" src={car} alt="" />
                      <img className="w-4 sm:w-6" src={left} alt="" />
                    </div>
                    <h3 className="text-sm font-semibold text-gray-900 dark:text-white">
                      Links abbiegen auf Beckinger Straße
                    </h3>
                  </div>

                  <div className="flex flex-row gap-2 py-4 sm:gap-6 items-center">
                    <div className="w-1/5 flex flex-row justify-around">
                      <img className="h-4 sm:h-6" src={car} alt="" />
                      <img className="w-4 sm:w-6" src={parking} alt="" />
                    </div>
                    <h3 className="text-sm font-semibold text-gray-900 dark:text-white">
                      Parken am Sportplatz
                    </h3>
                  </div>

                  <div className="flex flex-row gap-2 py-4 sm:gap-6 items-center">
                    <div className="w-1/5 flex flex-row justify-around">
                      <img className="h-4 sm:h-6" src={person} alt="" />
                      <img className="w-4 sm:w-6" src={left} alt="" />
                    </div>
                    <h3 className="text-sm font-semibold text-gray-900 dark:text-white">
                      Zu Fuß vor Realschule links abbiegen
                    </h3>
                  </div>

                  <div className="flex flex-row gap-2 py-4 sm:gap-6 items-center">
                    <div className="w-1/5 flex flex-row justify-around">
                      <img className="h-4 sm:h-6" src={person} alt="" />
                      <img className="h-4 sm:h-6" src={up} alt="" />
                    </div>
                    <h3 className="text-sm font-semibold text-gray-900 dark:text-white">
                      Geradeaus Eingang zur Halle
                    </h3>
                  </div>
                </div>
              </div>

              <div className="flow-root w-full mx-auto mt-14 lg:mt-14">
                <h3 className="text-md text-center font-bold text-gray-900 dark:text-white mb-6">Halleneingang hinten</h3>
                <div className="-my-4 divide-y divide-gray-200 dark:divide-gray-700">

                  <div className="flex flex-row gap-2 py-4 sm:gap-6 items-center">
                    <div className="w-1/5 flex flex-row justify-around">
                      <img className="h-4 sm:h-6" src={car} alt='' />
                      <img className="h-4 sm:h-6" src={up} alt='' />
                    </div>
                    <h3 className="text-sm font-semibold text-gray-900 dark:text-white">
                      Geradeaus auf Fremersdorfer Straße
                    </h3>
                  </div>

                  <div className="flex flex-row gap-2 py-4 sm:gap-6 items-center">
                    <div className="w-1/5 flex flex-row justify-around">
                      <img className="h-4 sm:h-6" src={car} alt='' />
                      <img className="h-4 sm:h-6" src={left} alt='' />
                    </div>
                    <h3 className="text-sm font-semibold text-gray-900 dark:text-white">
                      Links abbiegen auf Neustraße
                    </h3>
                  </div>

                  <div className="flex flex-row gap-2 py-4 sm:gap-6 items-center">
                    <div className="w-1/5 flex flex-row justify-around">
                      <img className="h-4 sm:h-6" src={car} alt='' />
                      <img className="w-4 sm:w-6" src={parking} alt='' />
                    </div>
                    <h3 className="text-sm font-semibold text-gray-900 dark:text-white">
                      Parken am Marktplatz
                    </h3>
                  </div>

                  <div className="flex flex-row gap-2 py-4 sm:gap-6 items-center">
                    <div className="w-1/5 flex flex-row justify-around">
                      <img className="h-4 sm:h-6" src={person} alt='' />
                      <img className="h-4 sm:h-6" src={up} alt='' />
                    </div>
                    <h3 className="text-sm font-semibold text-gray-900 dark:text-white">
                      Durch Tor Richtung Schulhof
                    </h3>
                  </div>

                  <div className="flex flex-row gap-2 py-4 sm:gap-6 items-center">
                    <div className="w-1/5 flex flex-row justify-around">
                      <img className="h-4 sm:h-6" src={person} alt='' />
                      <img className="h-4 sm:h-6" src={right} alt='' />
                    </div>
                    <h3 className="text-sm font-semibold text-gray-900 dark:text-white">
                      Rechts Richtung hinterer Eingang
                    </h3>
                  </div>
                </div>
              </div>

            </div>

            <div className="max-w-screen-xl px-4 flex-1 mx-auto flex flex-col mt-16 lg:mt-0">
              <h2 className="self-center  text-lg font-medium text-primary-600 dark:text-primary-500">
                von Wallerfangen
              </h2>
              <div className="flow-root w-full mx-auto mt-6 sm:mt-6 lg:mt-10">
                <h3 className="text-md text-center font-bold text-gray-900 dark:text-white mb-6">
                  Halleneingang vorne
                </h3>
                <div className="-my-4 divide-y divide-gray-200 dark:divide-gray-700">
                  <div className="flex flex-row gap-2 py-4 sm:gap-6 items-center">
                    <div className="w-1/5 flex flex-row justify-around">
                      <img className="h-4 sm:h-6" src={car} alt="" />
                      <img className="h-4 sm:h-6" src={up} alt="" />
                    </div>
                    <h3 className="text-sm font-semibold text-gray-900 dark:text-white">
                      Geradeaus auf Wallerfanger Straße
                    </h3>
                  </div>

                  <div className="flex flex-row gap-2 py-4 sm:gap-6 items-center">
                    <div className="w-1/5 flex flex-row justify-around">
                      <img className="h-4 sm:h-6" src={car} alt="" />
                      <img className="w-4 sm:w-6" src={right} alt="" />
                    </div>
                    <h3 className="text-sm font-semibold text-gray-900 dark:text-white">
                      Rechts abbiegen auf Beckinger Straße
                    </h3>
                  </div>

                  <div className="flex flex-row gap-2 py-4 sm:gap-6 items-center">
                    <div className="w-1/5 flex flex-row justify-around">
                      <img className="h-4 sm:h-6" src={car} alt="" />
                      <img className="w-4 sm:w-6" src={parking} alt="" />
                    </div>
                    <h3 className="text-sm font-semibold text-gray-900 dark:text-white">
                      Parken am Sportplatz
                    </h3>
                  </div>

                  <div className="flex flex-row gap-2 py-4 sm:gap-6 items-center">
                    <div className="w-1/5 flex flex-row justify-around">
                      <img className="h-4 sm:h-6" src={person} alt="" />
                      <img className="w-4 sm:w-6" src={left} alt="" />
                    </div>
                    <h3 className="text-sm font-semibold text-gray-900 dark:text-white">
                      Zu Fuß vor Realschule links abbiegen
                    </h3>
                  </div>

                  <div className="flex flex-row gap-2 py-4 sm:gap-6 items-center">
                    <div className="w-1/5 flex flex-row justify-around">
                      <img className="h-4 sm:h-6" src={person} alt="" />
                      <img className="h-4 sm:h-6" src={up} alt="" />
                    </div>
                    <h3 className="text-sm font-semibold text-gray-900 dark:text-white">
                      Geradeaus Eingang zur Halle
                    </h3>
                  </div>
                </div>
              </div>

              <div className="flow-root w-full mx-auto mt-14 lg:mt-14">
                <h3 className="text-md text-center font-bold text-gray-900 dark:text-white mb-6">Halleneingang hinten</h3>
                <div className="-my-4 divide-y divide-gray-200 dark:divide-gray-700">

                  <div className="flex flex-row gap-2 py-4 sm:gap-6 items-center">
                    <div className="w-1/5 flex flex-row justify-around">
                      <img className="h-4 sm:h-6" src={car} alt='' />
                      <img className="h-4 sm:h-6" src={up} alt='' />
                    </div>
                    <h3 className="text-sm font-semibold text-gray-900 dark:text-white">
                      Geradeaus auf Wallerfanger Straße
                    </h3>
                  </div>

                  <div className="flex flex-row gap-2 py-4 sm:gap-6 items-center">
                    <div className="w-1/5 flex flex-row justify-around">
                      <img className="h-4 sm:h-6" src={car} alt='' />
                      <img className="h-4 sm:h-6" src={right} alt='' />
                    </div>
                    <h3 className="text-sm font-semibold text-gray-900 dark:text-white">
                      Rechts abbiegen auf Neustraße
                    </h3>
                  </div>

                  <div className="flex flex-row gap-2 py-4 sm:gap-6 items-center">
                    <div className="w-1/5 flex flex-row justify-around">
                      <img className="h-4 sm:h-6" src={car} alt='' />
                      <img className="w-4 sm:w-6" src={parking} alt='' />
                    </div>
                    <h3 className="text-sm font-semibold text-gray-900 dark:text-white">
                      Parken am Marktplatz
                    </h3>
                  </div>

                  <div className="flex flex-row gap-2 py-4 sm:gap-6 items-center">
                    <div className="w-1/5 flex flex-row justify-around">
                      <img className="h-4 sm:h-6" src={person} alt='' />
                      <img className="h-4 sm:h-6" src={up} alt='' />
                    </div>
                    <h3 className="text-sm font-semibold text-gray-900 dark:text-white">
                      Durch Tor Richtung Schulhof
                    </h3>
                  </div>

                  <div className="flex flex-row gap-2 py-4 sm:gap-6 items-center">
                    <div className="w-1/5 flex flex-row justify-around">
                      <img className="h-4 sm:h-6" src={person} alt='' />
                      <img className="h-4 sm:h-6" src={right} alt='' />
                    </div>
                    <h3 className="text-sm font-semibold text-gray-900 dark:text-white">
                      Rechts Richtung hinterer Eingang
                    </h3>
                  </div>
                </div>
              </div>

            </div>
          </section>
        </div>
        <div className="flex flex-col lg:flex-row justify-center gap-8 mt-16 0">
          <div className="flex flex-col self-center bg-slate-200 p-6 rounded-b-3xl rounded-tr-3xl shadow-lg shadow-gray-400">
            <h2 className="text-lg font-bold mb-2">Schulturnhalle Rehlingen</h2>
            <h3 className="text-md">Beckinger Straße 38</h3>
            <h3 className="text-md">66780 Rehlingen</h3>
          </div>
          <div className="w-full sm:w-3/4 lg:w-2/3 h-60 sm:h-72 lg:h-96">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1092.193397817289!2d6.683666205685733!3d49.37697023495659!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479509b31922ed23%3A0x56b47ac59d823423!2sTTC%20Rehlingen!5e0!3m2!1sde!2sde!4v1613685012281!5m2!1sde!2sde"
              width="100%"
              height="100%"
              frameborder="0"
              allowfullscreen=""
              aria-hidden="false"
              tabindex="0"
              title="Google Maps Schulturnhalle Rehlingen"
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
}
