import React from 'react'
import download from '../assets/download-icon.png'

const DOWNLOAD_BASE_URL = 'https://ttcrehlingen.de/docs/'

export default function Docs() {
  return (
    <section className="bg-white w-full font-montserrat dark:bg-gray-900">
        <div className="py-8 sm:mx-auto">
            <div className="mx-auto max-w-screen-sm text-center lg:mb-16 mb-8 absolute top-80 sm:top-72 md:top-72 lg:top-72 xl:top-72 left-0 right-0">
                <h2 className="mb-4 text-2xl sm:text-3xl lg:text-4xl tracking-tight font-extrabold text-white dark:text-white">Downloads</h2>
                <p className="font-light text-slate-100 text-sm sm:text-lg lg:text-xl text-white dark:text-gray-400">Hier finden Sie alle wichtigen Dokumente und Formulare zum Herunterladen.</p>
            </div>
            <div className='bg-white min-h-50vh'>
                 <div className='w-full sm:w-4/5 lg:w-3/4 mx-auto px-4 pt-10 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 lg:gab-8 justify-center text-sm lg:text-lg'>
                    <a href={DOWNLOAD_BASE_URL + 'Beitrittserklärung.pdf'} download className='flex items-center p-10 justify-center rounded-2xl bg-gray-300 hover:scale-105 hover:cursor-pointer '>
                            <img src={download} alt="Verein" className="w-6 rounded-lg"/>
                        <p className="block py-3 px-5 text-gray-70">Antrag auf Mitgliedschaft</p>
                    </a>
                    <a href={DOWNLOAD_BASE_URL + 'Satzung.pdf'} download className='flex items-center p-10 justify-center rounded-2xl bg-gray-300 hover:scale-105 hover:cursor-pointer '>
                            <img src={download} alt="Verein" className="w-6 rounded-lg"/>
                        <p className="block py-3 px-5 text-gray-70">Satzung</p>
                    </a>
                </div> 

{/*                 <div className='w-full sm:w-4/5 lg:w-3/4 mx-auto px-4 pt-10 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 lg:gab-8 justify-center text-sm lg:text-lg'>
                    <p className="block py-3 px-5 text-gray-70">Coming soon</p>
                </div>  */}


            </div>
        </div>
    </section>
  )
}
