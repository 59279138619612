import React from 'react'

function Mannschaft({ selectedId }) {
    const data = [{
        id: 2018,
        year: "2018/19",
        mannschaften: [
            {
                name: "Herren 1",
                league: "Bezirksliga Süd-West Herren",
                place: 2
            },
            {
                name: "Herren 2",
                league: "3. Bezirksklasse Gr. 3",
                place: 3
            },
            {
                name: "Senioren 1",
                league: "Bezirksliga Süd-West Senioren",
                place: 4
            },
            {
                name: "Senioren 2",
                league: "1. Bezirksklasse Gr. 3",
                place: 9
            },
            {
                name: "Senioren 3",
                league: "4er Senioren Gr. 2",
                place: 7
            }
        ]
    },
    {
        id: 2019,
        year: "2019/20",
        mannschaften: [
            {
                name: "Herren 1",
                league: "Bezirksliga Süd-West Herren",
                place: 2
            },
            {
                name: "Herren 2",
                league: "3. Bezirksklasse Gr. 3",
                place: 3
            },
            {
                name: "Senioren 1",
                league: "Bezirksliga Süd-West Senioren",
                place: 4
            },
            {
                name: "Senioren 2",
                league: "1. Bezirksklasse Gr. 3",
                place: 9
            },
            {
                name: "Senioren 3",
                league: "4er Senioren Gr. 2",
                place: 7
            }
        ]
    },
    {
        id: 2020,
        year: "2020/21",
        mannschaften: [
            {
                name: "Herren 1",
                league: "Bezirksliga Süd-West Herren",
                place: 2
            },
            {
                name: "Herren 2",
                league: "3. Bezirksklasse Gr. 3",
                place: 3
            },
            {
                name: "Senioren 1",
                league: "Bezirksliga Süd-West Senioren",
                place: 4
            },
            {
                name: "Senioren 2",
                league: "1. Bezirksklasse Gr. 3",
                place: 9
            },
            {
                name: "Senioren 3",
                league: "4er Senioren Gr. 2",
                place: 7
            }
        ]
    },
    {
        id: 2021,
        year: "2021/22",
        mannschaften: [
            {
                name: "Herren 1",
                league: "Bezirksliga Süd-West Herren",
                place: 2
            },
            {
                name: "Herren 2",
                league: "3. Bezirksklasse Gr. 3",
                place: 3
            },
            {
                name: "Senioren 1",
                league: "Bezirksliga Süd-West Senioren",
                place: 4
            },
            {
                name: "Senioren 2",
                league: "1. Bezirksklasse Gr. 3",
                place: 9
            },
            {
                name: "Senioren 3",
                league: "4er Senioren Gr. 2",
                place: 7
            }
        ]
    },
    {
        id: 2022,
        year: "2022/23",
        mannschaften: [
            {
                name: "Herren 1",
                league: "Bezirksliga Süd-West Herren",
                place: 2
            },
            {
                name: "4er Herren",
                league: "4er Mannschaft West",
                place: 2
            },
            {
                name: "Senioren 1",
                league: "1. Bezirksklasse West",
                place: 3
            },
            {
                name: "4er Senioren",
                league: "4er Senioren S/W Gr. 1",
                place: 6
            }
        ]
    },
    {
        id: 2023,
        year: "2023/24",
        mannschaften: [
            {
                name: "Jugend 15",
                league: "Bezirksklasse A",
                place: 5
            },
            {
                name: "4er Herren",
                league: "4er Mannschaft Gruppe C",
                place: 3
            },
            {
                name: "Senioren 1",
                league: "1. Bezirksklasse Gruppe C",
                place: 5
            },
            {
                name: "Senioren 2",
                league: "4er Senioren Gruppe B",
                place: 7
            }
        ]
    }
    ]


    return (
        <div>

            {data.filter(item => item.id === selectedId).map(item => (
                <div key={item.id} className="block max-w-lg m-6 p-6 cursor-default bg-slate-100 border border-gray-200 rounded-lg shadow hover:bg-slate-200 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                    <h5 className="mb-2 text-lg lg:text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Saison: {item.year}</h5>
                    <div className="flex flex-row  ">
                        <div className="">
                            <h5 className="border-b-2 border-b-slate-400 p-1 font-semibold text-sm lg:text-xl">Mannschaft</h5>
                            {item.mannschaften.map(mannschaft => (
                                <h6 className="p-1 text-xs h-12 lg:text-sm">{mannschaft.name}</h6>
                            ))}
                        </div>
                        <div className="">
                            <h5 className="border-b-2 border-b-slate-400 p-1 font-semibold text-sm lg:text-xl">Liga</h5>
                            {item.mannschaften.map(mannschaft => (
                                <h6 className="p-1 text-xs h-12 lg:text-sm">{mannschaft.league}</h6>
                            ))}
                        </div>
                        <div className="text-center">
                            <h5 className="border-b-2 border-b-slate-400 p-1 font-semibold text-sm lg:text-xl">Platz</h5>
                            {item.mannschaften.map(mannschaft => (
                                <h6 className="p-1 text-xs h-12 lg:text-sm">{mannschaft.place}</h6>
                            ))}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default Mannschaft