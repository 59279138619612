
import { useEffect, useState } from 'react'

const useFetch = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);


  const [posts, setPosts] = useState([]);

    useEffect(() => {
        const importPosts = async () => {
            setLoading(true);
            const context = require.context('../../content/posts', false, /\.md$/);
            const posts = [];
            let sortedPosts = [];

            for (const key of context.keys()) {
                const fileName = key.substring(2); // Remove './' from the start
                const fileContent = await fetch(require(`../../content/posts/${fileName}`))
                    .then((res) => res.text())
                    .catch((error) => setError(error));
                const jsonFileContent = markdownToJSON(fileContent);

                posts.push(jsonFileContent);
            }
            
            sortedPosts = posts.sort((a, b) => new Date(b.metadata.date) - new Date(a.metadata.date));
            setPosts(sortedPosts);
            setLoading(false);
        };

        importPosts();
    }, []);


  return {loading, error, posts}
}

export default useFetch


const markdownToJSON = (markdown) => {
  const lines = markdown.split('\n');
  const metadata = {};
  const images = [];
  let content = '';

  let inMetadata = false;
  let currentArrayKey = null;

  lines.forEach((line, index) => {
    if (line.trim() === '---') {
      inMetadata = !inMetadata;
      currentArrayKey = null;
    } else if (inMetadata) {
      if (line.trim() === '') return;

      if (line.startsWith('  - ') && currentArrayKey) {
        if (currentArrayKey === 'images') {
          images.push(line.trim().substring(3));
        } else {
          metadata[currentArrayKey].push(line.trim().substring(3));
        }
      } else if (line.includes(':')) {
        const [key, ...valueParts] = line.split(':');
        const value = valueParts.join(':').trim();

        if (value === '') {
          if (key === 'images') {
            currentArrayKey = 'images';
          } else {
            metadata[key] = [];
            currentArrayKey = key;
          }
        } else {
          metadata[key.trim()] = value;
          currentArrayKey = null;
        }
      }
    } else {
      // Prüfe auf tatsächliche leere Zeilen, die für Absatzwechsel verwendet werden sollen
      if (line.trim() === '') {
        console.log(lines[index -1])
        // Überprüfen, ob die vorherige Zeile auch leer ist, um keine doppelten <br><br> zu erzeugen
        if (index > 0 && lines[index - 1].trim() !== '') {
          content += '<br><br>';
        }
      } else {
        content += line + '\n';
      }
    }
  });

  return {
    metadata,
    images,
    content: content.trim(),
  };
};





