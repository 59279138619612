import React from 'react'
import logo from '../assets/logo.png'
import NavBarDropdown from './NavBarDropdown'
import MenuIcon from '../assets/menu-icon.png'
import DownIcon from '../assets/down-icon.png'

export default function NavBar() {
    const [openNav, setOpenNav] = React.useState(false);
    const [openSubMenu, setOpenSubMenu] = React.useState(false);

    return (
        <header>
            <nav className="bg-white h-22 relative max-h-22 border-gray-200 px-4 lg:px-6 py-1 dark:bg-gray-800">
                <div className="flex flex-row flex-1 justify-between items-center py-2">
                    <a href="/" className="flex flex-row items-center ">
                        <img src={logo} className="mr-3 w-16 " alt="TTC Logo" />
                        <span className="self-center text-lg sm:text-xl font-semibold whitespace-nowrap dark:text-white">TTC Rehlingen</span>
                    </a>
                    <div className="hidden items-center justify-end w-full lg:flex flex-1">
                        <ul className="flex flex-col mt-4 font-medium lg:flex-row space-x-4 xl:space-x-8 lg:mt-0">
                            
                            <li>
                                <a href="/news" className="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:text-blue-500 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">Aktuelles</a>
                            </li>
                            <li>
                                <NavBarDropdown />
                            </li>
                            <li>
                                <a href="/anfahrt" className="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:text-blue-500 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">Anfahrt</a>
                            </li>
                            <li>
                                <a href="/kontakt" className="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:text-blue-500 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">Kontakt</a>
                            </li>
                            <li>
                                <a href="/docs" className="text-white bg-slate-700 bg-teal-700 hover:bg-teal-900 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800">Mitglied werden</a>
                            </li>

                        </ul>
                    </div>
                    <div className="lg:hidden">
                        <button onClick={() => {setOpenNav(!openNav); setOpenSubMenu(false)}} className="flex items-center px-3 py-2 rounded text-gray-500 border-gray-600 hover:text-blue-500 hover:border-blue-500 dark:text-gray-400 dark:border-gray-700 dark:hover:text-white dark:hover:border-white" id="mobile-menu-button">
                            <img src={MenuIcon} alt="Menu" className="w-6" />
                        </button>
                        <div className={`items-center ${openNav ? 'block' : 'hidden'} absolute z-10 left-0 right-0 top-22 bottom-0 justify-end w-full bg-black bg-opacity-60`}>
                            <div className={`items-center absolute left-0 right-0 top-0 z-10 justify-end w-full bg-white border-b-4 border-teal-800`} >
                                <ul className="flex flex-col font-medium">    
                                    <li>
                                        <a href="/news" className="text-gray-700 hover:text flex items-center px-4 h-12 border-b border-gray-100" >Aktuelles</a>
                                    </li>   
                                    <li onClick={() => setOpenSubMenu(!openSubMenu)} className=' flex items-center px-4 h-12 border-b border-gray-100'>
                                        <p className="text-gray-700 hover:text" >Verein</p>
                                        <img src={DownIcon} alt="Down" className="w-4 ml-auto" />
                                    </li> 
                                    <div className={`${openSubMenu ? 'block' : 'hidden'}`}>
                                        <li>
                                            <a href="/vorstand" className="text-gray-700 hover:text-primary-700 flex items-center px-4 pl-12 h-12 border-b border-gray-100" >Vorstand</a>
                                        </li>   
                                        <li>
                                            <a href="/mannschaften" className="text-gray-700 hover:text-primary-700 flex items-center px-4 pl-12 h-12 border-b border-gray-100" >Mannschaften</a>
                                        </li> 
                                        <li>
                                            <a href="/chronik" className="text-gray-700 hover:text-primary-700 flex items-center px-4 pl-12 h-12 border-b border-gray-100" >Chronik</a>
                                        </li> 
                                        <li>
                                            <a href="/docs" className="text-gray-700 hover:text-primary-700 flex items-center px-4 pl-12 h-12 border-b border-gray-100" >Downloads</a>
                                        </li>    
                                    </div>    
                                    <li>
                                        <a href="/anfahrt" className="text-gray-700 hover:text-primary-700 flex items-center px-4 h-12 border-b border-gray-100" >Anfahrt</a>
                                    </li>   
                                    <li>
                                        <a href="/kontakt" className="text-gray-700 hover:text-primary-700 flex items-center px-4 h-12 border-b border-gray-100" >Kontakt</a>
                                    </li>   
                                    <li>
                                        <a href="/docs" className="text-teal-600 font-semibold hover:text-primary-700 flex items-center px-4 h-12 border-b border-gray-100" >Mitglied werden</a>
                                    </li>   
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    )
}
