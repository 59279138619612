import React from 'react'
import Logo from '../assets/logo.png'

export default function Footer() {
    return (
        <footer className="p-4 bg-gray-300 sm:p-8 lg:p-10 dark:bg-gray-800min-w-full self-end">
            <div className="mx-auto max-w-screen-xl text-center">
                <a href="/" className="flex justify-center items-center text-lg sm:text-2xl font-semibold text-gray-900 dark:text-white">
                    <img src={Logo} className="w-8 sm:w-10 mr-2" alt="TTC Logo"></img>
                    TTC Rehlingen
                </a>
                <p className="my-6 text-sm sm:text-lg text-gray-500 dark:text-gray-400">Zusammen spielen, zusammenhalten - unsere Tischtennis-Familie!</p>
                <ul className="flex flex-wrap text-xs sm:text-sm lg:text-lg justify-center items-center mb-6 text-gray-900 dark:text-white">
                    
                    <li>
                        <a href="https://www.mytischtennis.de/clicktt/STTB/22-23/verein/328/TTC-Rehlingen/mannschaften/" target="_blank" rel='noreferrer' className="mr-4 hover:underline md:mr-6 ">click-TT</a>
                    </li>
                    <li>
                        <a href="/datenschutz" className="mr-4 hover:underline md:mr-6">Datenschutz</a>
                    </li>
                    <li>
                        <a href="/impressum" className="mr-4 hover:underline md:mr-6">Impressum</a>
                    </li>
                    <li>
                        <a href="/faq" className="mr-4 hover:underline md:mr-6">FAQs</a>
                    </li>
                    <li>
                        <a href="/admin" className="mr-4 hover:underline md:mr-6">Login</a>
                    </li>
                </ul>
                <span className="text-xs sm:text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2023 <a href="/" className="hover:underline">TTC Rehlingen</a>. All Rights Reserved.</span>
            </div>
        </footer>
    )
}
