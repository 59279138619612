import React from 'react'

export default function Chronic() {
  return (
    <section className="bg-white w-full dark:bg-gray-900">
            <div className="py-8 w-full lg:px-0">
                <div className="mx-auto max-w-screen-sm text-center lg:mb-16 mb-8 absolute top-80 sm:top-72 md:top-72 lg:top-72 xl:top-72 left-0 right-0">
                    <h2 className="mb-4 text-2xl sm:text-3xl lg:text-4xl tracking-tight font-extrabold text-white dark:text-white">Chronik</h2>
                    <p className="font-light text-slate-100 text-sm sm:text-lg lg:text-xl text-white dark:text-gray-400">des Tischtennisclub Rehlingen 1964 e.V.</p>
                </div>
                <div className='w-full'>
                    <div className='w-full sm:w-4/5 lg:w-3/4 mx-auto px-2 pt-0 text-sm sm:text-lg lg:text-xl'>
                        <p className='leading-loose'>60 Jahre Tischtennis in Rehlingen. Dies bedeutet, dass seit 1964 der Tischtennissport in Rehlingen Freunde gefunden hat.</p>
                        <br/>
                        <p className='leading-loose'>Ganze 16 Mitglieder zählte unser Verein am 21. April 1964, unserem Gründungstag. Bei unserem 25-jährigen Jubiläum 1989 war unsere Mitgliederzahl bereits auf beachtliche 143 gestiegen. Erfreulicherweise hat sich dieser Trend fortgesetzt und wir können heute nach 52 Jahren auf eine stolze Mitgliederzahl von 178 blicken.</p>
                        <br/>
                        <p className='leading-loose'>Doch zunächst wieder zurück in unser Gründungsjahr 1964. Bei der Gründungsversammlung im Cafe´Heinrich waren die folgenden neun Mitglieder anwesend: Herbert Dening, Ernst Spelz, Klaus Mauer, Rainer Heffinger, Benno Rupp, Alfred Schramm, Hans Lorreng, Günther Brossette und Hans Steinwender. Aus diesen wenigen Gründungsmitgliedern wurde dann auch der Vorstand gewählt, der sich wie folgt zusammensetzte: Den Posten des 1. Vorsitzenden übernahm Herbert Dening, 2. Vorsitzender war Ernst Spelz, Benno Rupp fungierte als Schriftführer und Klaus Mauer übernahm das Amt des Kassierers. Zum aktiven Spielbetrieb wurden anfangs zwei Herrenmannschaften gemeldet.</p>
                        <br/>
                        <p className='leading-loose'>Während die 1. Mannschaft sich auf Anhieb in der B-Klasse Westsaar unter die ersten drei plazieren konnte (sie spielte in der Aufstellung Herbert Dening, Hans Klasen, Balle, Kurt Schramm, Alfons Rein und Rainer Heffinger), musste die 2. Mannschaft in die neu geschaffene C-Klasse absteigen.</p>
                        <br/>
                        <p className='leading-loose'>Auch in den folgenden Jahren konnte die 1. Mannschaft stets gut mithalten und war entweder im oberen oder doch zumindest mittleren Tabellenfeld zu finden. Zwischenzeitlich war auch eine 3. Herrenmannschaft zum aktiven Spielbetrieb angemeldet worden. Dazu kamen noch eine Senioren- und eine Damenmannschaft. In der Folge wurde das Interesse am Tischtennissport immer größer, dementsprechend auch die Anzahl der gemeldeten Mannschaften. Im Lauf der Vereinsgeschichte können wir auf mehrfache Pokalsiege und Meisterschaften unserer aktiven Mannschaften zurück blicken.</p>
                        <br/>
                        <p className='leading-loose'>Da der Nachwuchs bekanntlich die Stütze eines jeden Vereins ist, wurde dieser durch gezielte Werbung stark gefördert. Dabei stellten sich auch schon bald die ersten erhofften Erfolge ein. Sowohl die Schüler- als auch die Jungenmannschaften konnten im Laufe der Vereinsgeschichte mehrfach zu Meisterehren kommen.</p>
                        <br/>
                        <p className='leading-loose'>Aktuell können wir seit 2011 auf eine erfolgreiche Entwicklung in unserem Schülerbereich verweisen. Mehrfache Kreismeistertitel im Einzel und Doppel, weitere sehr gute Plazierungen bei Kreiseinzel- und Landesmeisterschaften, Kreispokalsiege sowie drei Meistertitel nacheinander spiegeln die sportlichen Erfolge wieder.</p>
                        <br/>
                        <p className=' leading-loose'>In diese Zeit fällt auch der erste Saarlandmeistertitel in der Vereinsgeschichte des TTC Rehlingen. Am 23.10.2011 konnten Michelle Erbelding und Simon Klasen den Landesmeistertitel im Mixed-Wettbewerb der Schüler C (U11) gewinnen.</p>
                        <br/>
                        <p className=' leading-loose'>Im Jubiläumsjahr 2014 nahm unser Verein mit zwei Herren-, zwei Damen-, zwei Senioren-, zwei Schülermannschaften sowie mit einer Jugendmannschaft am laufenden Spielbetrieb teil.</p>
                        <br/>
                        <p className='leading-loose'>Unsere Schüler A-Mannschaft war auch in der Saison 2013/2014 sehr erfolgreich und gewann den Kreispokal sowie den Meistertitel in der Kreisliga Westsaar.</p>
                        <br/>
                        <p className='leading-loose'>In der abgelaufenen Saison 2015/2016 haben wir eine Herren-, eine Damen-, zwei Senioren-, eine Schülermannschaft sowie eine Jungenmannschaft gemeldet.</p>
                        <p></p>
                        <p></p>
                    </div>
                </div>
            </div>
        </section>
  )
}
