import { MantineProvider } from "@mantine/core";
import Footer from "./components/Footer";
import NavBar from "./components/NavBar";
import Navigation from "./router/Navigation";
import BackgroundImg from "./assets/background.png";



function App() {
  return (
    <MantineProvider withGlobalStyles withNormalizeCSS>
      <div className="font-montserrat">
        <header>
          <NavBar />
        </header>
        <main>
        <div className="relative w-full h-50v min-h-80">
          <img src={BackgroundImg} alt="background" className="w-full h-full object-cover" />
          <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50"></div>
        </div>
          <Navigation />
        </main>
        <footer>
          <Footer />
        </footer>
      </div>
     </MantineProvider>
  );
}

export default App;
