import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import useFetch from '../hooks/useFetch';
import {Shimmer} from 'react-shimmer';
import { marked } from 'marked';
import ImageCarousel from '../components/ImageCarouselFlowbite';

export default function Post() {
    const {id} = useParams();
    const {posts, loading} = useFetch();
    const [openCarousel, setOpenCarousel] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);

    const post = posts[id];


      const handleImageClick = (index) => {
        setActiveIndex(index);
        setOpenCarousel(true);
      };

  return (
    <section className="bg-white pb-10 dark:bg-gray-900 font-montserrat">
        {!loading && post &&
        <div className='bg-white min-h-50vh p-4 pt-6'>
            <div>
                <p className='text-center text-gray-600 text-xs sm:text-sm font-medium mb-2'>{new Date(post.metadata.date).toLocaleString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit'})}</p>
                <h1 className='text-center font-semibold text-xl sm:text-3xl'>{post.metadata.title}</h1>
                <div className='lg:max-w-5xl sm:max-w-3xl max-w-xl py-8 mb-8 mx-auto text-sm font-medium sm:text-lg ' dangerouslySetInnerHTML={{__html: marked(post.content)}} />
            </div>

            <div className='w-full xl:w-3/4 mx-auto grid grid-cols-1 lg:grid-cols-2 gap-4 md:gap-6 xl:gap-8'>
                <div class="w-full mx-auto grid grid-cols-1 gap-4 lg:grid-cols-2 md:gap-6 xl:gap-8">    
                    {post.images && post.images.filter((img, index) => index < Math.ceil(post.images.length/2)).map((image, index) => (
                        <div onClick={() => handleImageClick(index)} class={`group relative flex h-64 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg ${index % 3 === 0 ? 'md:col-span-2 sm:h-96' : 'sm:h-72'}`}>
                            <img loading='lazy' className='absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110 hover:cursor-pointer' key={index} src={`/${image}`} alt={''}  />
                        </div>
                    ))}
                </div>
                <div class="w-full mx-auto grid grid-cols-1 gap-4 lg:grid-cols-2 md:gap-6 xl:gap-8">    
                    {post.images && post.images.filter((img, index) => index >= Math.ceil(post.images.length/2)).map((image, index) => (
                        <div onClick={() => handleImageClick(index + Math.ceil(post.images.length/2))} class={`group relative flex h-64 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg ${(index + 1) % 3 === 0 ? 'md:col-span-2 sm:h-96' : 'sm:h-72'}`}>
                            <img loading='lazy' className='absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110 hover:cursor-pointer' key={index} src={`/${image}`} alt={''}  />
                        </div>
                    ))}
                </div>
            </div>

        </div>}
        {loading && 
            <div className='flex flex-col bg-white items-center min-h-50vh'>
                <Shimmer height={60} className='mt-4'/>
                <Shimmer height={300} width={800} className='my-4'/>
            </div>
        }

        <ImageCarousel openModal={openCarousel} closeModal={() => setOpenCarousel(false)} activeSlide={activeIndex} images={post && post.images && post.images.length > 0 && post.images.map(image => ({src: `/${image}`, alt: 'image'}))} />
    </section>
  )
}
