
import { Link } from 'react-router-dom'
import Logo from '../assets/logo.png'
import useFetch from '../hooks/useFetch'


export default function ArticleOverview() { 
    const { posts } = useFetch()

    console.log(posts[0])

    return (
        <section className="bg-white dark:bg-gray-900 mt-16">
            <div className="mx-auto max-w-screen-xl">
                <div className="py-8 md:py-0 px-4 min-w-screen-md mb-8 lg:mb-12 text-center bg-gray-700 md:bg-white">
                    <h2 className="mb-4 text-2xl sm:text-3xl md:text-4xl tracking-tight font-extrabold text-white md:text-gray-900 dark:text-white">News - auf einen Blick</h2>
                    <p className="text-white md:text-gray-500 text-sm sm:text-lg md:text-xl dark:text-gray-400">Alle Neuigkeiten und Artikel findest du oben unter <a href='/news' className='text-blue-400 cursor-pointer'>Aktuelles</a>.</p>
                </div>
                <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 sm:gap-14 mx-8 sm:mx-0 px-4 '>
                    {posts.filter((d, index) => index < 4).map((post, index) => (
                        <Link to={`/news/${index}`} key={index} className={`flex flex-col items-center justify-center sm:mb-0`}>
                            <div className='flex items-center flex-col'>
                                <p className='text-xs sm:text-sm text-center mb-2 text-gray-500'>{new Date(post.metadata.date).toLocaleString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit'})}</p>
                                <img alt='Bild' className='rounded-full object-cover h-40 w-40 sm:h-48 sm:w-48' src={post.metadata.prev_image != null ? (post.metadata.prev_image) : Logo} />
                            </div>
                            <div className='max-h-52 flex flex-col justify-between w-full h-full'>
                                <h3 className='text-sm sm:text-md lg:text-lg text-center font-semibold mt-2'>{post.metadata.title.length > 50 ? post.metadata.title.slice(0,50) + ' ...' : post.metadata.title}</h3>
                                <div className='w-full sm:w-3/4 mx-auto lg:w-auto  mt-2'>
                                    
                                    <Link to={`/news/${index}`} className='text-xs sm:text-sm text-blue-500 cursor-pointer'>Weiterlesen</Link>
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>
                {posts.length === 0 && <p className='text-center text-gray-500'>Noch keine Artikel vorhanden</p>}
            </div>
        </section>
    )
}
